import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Box, Flex, Text } from "rebass/styled-components";
import { rgba } from "polished";
import Glide, {
  Controls,
  Breakpoints,
  Keyboard,
  Swipe,
} from "@glidejs/glide/dist/glide.modular.esm";
import "@glidejs/glide/dist/css/glide.core.css";

import Quote from "../images/quote.inline.svg";

const StyledQuote = styled(Quote)`
  width: 3rem;
  height: 3rem;
`;

const GlideSlides = styled.ul`
  margin: 0;
  padding: 0;
`;

const GlideBullets = styled(Flex)`
  margin-bottom: 2rem;
  justify-content: center;
`;

const GlideBullet = styled.button`
  appearance: none;
  -webkit-appearance: none;
  width: 0.75rem;
  height: 0.75rem;
  display: inline-block;
  padding: 0;
  margin: 0 0.5rem;
  background-color: #fff;
  border: 0;
  border-radius: 0.75rem;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: ${(props) => rgba(props.theme.colors.text, 0.7)};
  }

  &.glide__bullet--active {
    background-color: ${(props) => props.theme.colors.text};
  }
`;

const Review = ({ title, content }) => (
  <Box variant="quotation">
    <StyledQuote />
    <Text dangerouslySetInnerHTML={{ __html: content }} />
    <Text sx={{ fontSize: 21, fontWeight: "bold" }}>{title}</Text>
  </Box>
);

const Reviews = () => {
  const data = useStaticQuery(graphql`
    query ReviewsQuery {
      reviews: allWordpressWpRecenze {
        edges {
          node {
            slug
            title
            content
          }
        }
      }
    }
  `);

  const { edges: reviews } = data.reviews;

  useEffect(() => {
    if (typeof window !== "undefined" && reviews !== null) {
      const options = {
        type: "carousel",
        gap: 0,
        perView: 1,
        peek: 0,
        breakpoints: {
          768: {
            peek: 0,
          },
        },
      };

      new Glide("#reviews-carouse", options).mount({
        Controls,
        Breakpoints,
        Keyboard,
        Swipe,
      });
    }
  }, []);

  return (
    <Flex
      sx={{
        justifyContent: "center",
      }}
    >
      <div className="glide" id="reviews-carouse">
        <div className="glide__track" data-glide-el="track">
          <GlideSlides className="glide__slides">
            {reviews.map((review) => (
              <li
                className="glide__slide"
                style={{ height: "inherit" }}
                key={review.node.slug}
              >
                <Flex
                  sx={{
                    p: 4,
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <Review
                    title={review.node.title}
                    content={review.node.content}
                  />
                </Flex>
              </li>
            ))}
          </GlideSlides>

          <GlideBullets data-glide-el="controls[nav]">
            {reviews.map((review, i) => (
              <GlideBullet type="button" data-glide-dir={`=${i}`} />
            ))}
          </GlideBullets>
        </div>
      </div>
    </Flex>
  );
};

Review.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Reviews;
