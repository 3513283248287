import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Box, Flex } from "rebass/styled-components";
import RoomTypeCard from "./RoomTypeCard";

const RoomTypesPage = ({ posts, scrollToReservation, linkToPricelist }) => {
  posts.sort((a, b) => a.node.menu_order - b.node.menu_order);

  return (
    <Flex flexDirection={["column", "row"]}>
      {posts.map(({ node: post }) => (
        <Box key={post.id} width={[1, 1 / 2]}>
          <RoomTypeCard
            title={post.title}
            image={
              <Img
                fluid={post.featured_media.localFile.childImageSharp.fluid}
                alt={post.featured_media.alt_text}
              />
            }
            link={
              linkToPricelist
                ? `/chaloupky/${post.slug}/cenik`
                : `/chaloupky/${post.slug}`
            }
            slug={post.slug}
            actionButtonLabel={
              linkToPricelist ? "Zobrazit ceník →" : "Více informací →"
            }
            color={post.acf.color}
            inProgress={post.acf.in_progress}
            scrollToReservation={scrollToReservation}
          />
        </Box>
      ))}
    </Flex>
  );
};

RoomTypesPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  scrollToReservation: PropTypes.bool,
  linkToPricelist: PropTypes.bool,
};

RoomTypesPage.defaultProps = {
  scrollToReservation: false,
  linkToPricelist: false,
};

export default RoomTypesPage;

export const pageQuery = graphql`
  fragment RoomTypesListFields on wordpress__wp_mphb_room_type {
    id
    title
    menu_order
    featured_media {
      alt_text
      localFile {
        childImageSharp {
          fluid(maxWidth: 1280, maxHeight: 720) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    excerpt
    slug
    acf {
      color
      in_progress
    }
  }
`;
