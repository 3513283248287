import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { rgba } from "polished";
import { Box, Flex } from "rebass/styled-components";
import Layout from "../components/Layout";
import { Section, SectionTitle, WoodSection } from "../components/Section";
import Container from "../components/Container";
import Hero from "../components/Hero";
import Carousel from "../components/Carousel";
import Card from "../components/Card";
import RoomTypesList from "../components/RoomTypesList";
import PostList from "../components/PostList";
import Reviews from "../components/Reviews";
import { GhostLink } from "../components/Buttons";
import PostTags from "../components/PostTags";
import Iframe from "../components/Iframe";

const IntroCardWrapper = styled.div`
  position: relative;
  margin: -4rem 0 0;
  z-index: 2;
`;

const CardBody = styled.div`
  & img {
    max-width: calc(100% + 5rem);
    height: auto;
    margin-left: -2.5rem;
  }
`;

const RoomsTitle = styled.h2`
  position: relative;
  margin: 0 auto;
  padding: 0.75rem 1rem;
  background-color: ${(props) => props.theme.colors.accent};
  color: #fff;
  text-align: center;
  z-index: 1;

  a {
    color: #fff;
    text-decoration: none;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }

  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: ${(props) => rgba(props.theme.colors.accent, 0)};
    border-top-color: ${(props) => props.theme.colors.accent};
    border-width: 12px;
    margin-left: -12px;
  }
`;

export default class IndexPage extends React.Component {
  render() {
    const { data /* , pageContext */ } = this.props;
    const { edges: news } = data.news;
    const { edges: experiences } = data.experiences;
    const { nodes: tags } = data.allWordpressTag;
    const { edges: rooms } = data.allWordpressWpMphbRoomType;
    const { description } = data.allWordpressSiteMetadata.edges[0].node;
    const homepage = data.homepage.edges[0].node;
    const homepage_gallery = homepage.acf.homepage_gallery;
    const heroTitle = homepage.acf.hero_title;
    const heroImage = homepage.acf.homepage_hero_image;

    return (
      <Layout ishome>
        <Helmet>
          <meta name="description" content={description} />
        </Helmet>

        <Hero image={heroImage} text={heroTitle} />

        {homepage && (
          <Section offset="4rem" style={{ backgroundColor: "#fcfbf6" }}>
            <Container narrow>
              <IntroCardWrapper>
                <Card large title={homepage.title} titleCentered>
                  <CardBody
                    dangerouslySetInnerHTML={{ __html: homepage.content }}
                  />
                </Card>
              </IntroCardWrapper>
            </Container>
          </Section>
        )}

        {rooms && (
          <Box style={{ position: "relative" }}>
            <RoomsTitle as="h2">
              <Link to="/chaloupky/">
                {rooms.length > 1 ? "Naše chaloupky" : "Naše chaloupka"}
              </Link>
            </RoomsTitle>
            <RoomTypesList posts={rooms} />
          </Box>
        )}

        {news.length > 0 && (
          <Section id="posts" style={{ backgroundColor: "#fcfbf6" }}>
            <Container>
              <Box mb={2}>
                <SectionTitle large textAlign="center" as="h2">
                  Aktuality
                </SectionTitle>
              </Box>

              <PostList posts={news} />

              <Flex justifyContent="center" mb={4}>
                <GhostLink to="/kategorie/aktuality/">
                  Všechny aktuality
                </GhostLink>
              </Flex>
            </Container>
          </Section>
        )}

        <Box sx={{ overflow: "hidden" }}>
          <Carousel
            photos={homepage_gallery}
            options={{
              type: "slider",
              gap: 0,
              perView: 1,
              peek: 0,
              breakpoints: {
                768: {
                  peek: 0,
                },
              },
            }}
          />
        </Box>

        {experiences && (
          <Section id="posts" style={{ backgroundColor: "#fcfbf6" }}>
            <Container>
              <Box mb={2}>
                <SectionTitle large textAlign="center" as="h2">
                  Nejnovější zážitky
                </SectionTitle>
              </Box>
              <Flex justifyContent="center" mb={4}>
                <PostTags tags={tags} largeTags />
              </Flex>

              <PostList posts={experiences} />

              <Flex justifyContent="center" mb={4}>
                <GhostLink to="/kategorie/zazitky/">Všechny zážitky</GhostLink>
              </Flex>
              {/* <Pagination pageContext={pageContext} pathPrefix="/" /> */}
            </Container>
          </Section>
        )}

        <Section id="reviews">
          <Container>
            <Box mb={2}>
              <SectionTitle large textAlign="center" as="h2">
                Recenze
              </SectionTitle>
            </Box>

            <Reviews />
          </Container>
        </Section>
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
};

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    homepage: allWordpressPage(filter: { path: { eq: "/" } }) {
      edges {
        node {
          path
          title
          content
          acf {
            hero_title
            homepage_hero_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 960, maxHeight: 960, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            homepage_gallery {
              localFile {
                id
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    news: allWordpressPost(
      limit: $limit
      skip: $skip
      filter: { categories: { elemMatch: { slug: { eq: "aktuality" } } } }
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    experiences: allWordpressPost(
      limit: $limit
      skip: $skip
      filter: { categories: { elemMatch: { slug: { eq: "zazitky" } } } }
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    allWordpressTag {
      nodes {
        id
        slug
        name
        description
        count
      }
    }
    allWordpressWpMphbRoomType(sort: { fields: menu_order, order: ASC }) {
      edges {
        node {
          ...RoomTypesListFields
        }
      }
    }
    allWordpressSiteMetadata {
      edges {
        node {
          name
          home
          description
        }
      }
    }
  }
`;
