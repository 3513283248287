import React from 'react';
import styled from 'styled-components';
import { rgba, readableColor } from 'polished';
// import TextTruncate from 'react-text-truncate';
import htmlToText from 'html-to-text';
import { GhostLink } from './Buttons';

const StyledImageWrapper = styled.div`
  transform: ${props => (props.blurred ? 'scale3d(1.05, 1.05, 1.05)' : 'scale3d(1, 1, 1)')};
  transition: transform 0.35s ease-in-out;
  filter: blur(${props => (props.blurred ? '2px' : '0')});
`;

const StyledCard = styled.div`
  position: relative;
  overflow: hidden;

  &:hover ${StyledImageWrapper} {
    transform: scale3d(1.1, 1.1, 1);
  }
`;

const StyledExcerpt = styled.p`
  position: absolute;
  margin: 0;
  padding: 1rem;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => (props.backgroundColor ? props.backgroundColor : props.theme.colors.accent)};
  color: ${props => readableColor((props.backgroundColor ? props.backgroundColor : props.theme.colors.accent), '#333', '#fff')};
  transform: translateY(100%);
  transition: transform 0.2s ease-in-out;
`;

const StyledBody = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => rgba(props.theme.colors.text, 0.4)};
  color: #fff;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out;

  &:focus,
  &:Hover {
    background-color: ${props => rgba(props.theme.colors.text, 0.6)};
  }

  &:focus ${StyledExcerpt},
  &:hover ${StyledExcerpt} {
    transform: translateY(0);
  }
`;

const StyledTitle = styled.h2`
  margin-top: 1rem;
  font-size: 3rem;
  text-shadow: 0 2px 8px ${props => props.theme.colors.text};
`;

const StyledGhostLink = styled(GhostLink)`
  flex-grow: 0;
  border-color: #fff;
  color: #fff;
`;

const RoomTypeCard = ({
  title,
  excerpt,
  image,
  link,
  actionButtonLabel,
  color,
  inProgress,
  scrollToReservation,
}) => (
  <StyledCard>
    <StyledImageWrapper blurred={inProgress}>{image}</StyledImageWrapper>
    <StyledBody>
      <StyledTitle as="h2">{title}</StyledTitle>
      {!inProgress && (
        <StyledGhostLink to={link} state={{ scrollToReservation }}>
          {actionButtonLabel}
        </StyledGhostLink>
      )}
      {excerpt && (
        <StyledExcerpt backgroundColor={color}>
          {/* <TextTruncate
          text={}
          line={3}
          element="span"
        /> */}
          {htmlToText.fromString(excerpt)}
        </StyledExcerpt>
      )}
    </StyledBody>
  </StyledCard>
);

export default RoomTypeCard;
