import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";

const HeroWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(60vh);
  z-index: 1;

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    min-height: calc(80vh);
  }

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    min-height: calc(100vh);
  }
`;

const HeroBody = styled.div`
  position: relative;
  padding: 0 1rem;
  text-align: center;
  z-index: 10;
`;

const HeroTitle = styled.h1`
  margin-top: 5rem;
  color: #ffffff;
  font-size: 2rem;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    margin-top: 0;
    font-size: 4rem;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: 5rem;
  }
`;

const HeroImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    background-attachment: fixed;
  }
`;

const Hero = ({ image, text }) => (
  <HeroWrapper>
    <HeroImage>
      <StyledBackgroundImage
        fadeIn={false}
        loading="eager"
        fluid={image.localFile.childImageSharp.fluid}
        key={image.localFile.id}
      />
    </HeroImage>

    <HeroBody>
      <HeroTitle dangerouslySetInnerHTML={{ __html: text }} />
    </HeroBody>
  </HeroWrapper>
);

Hero.propTypes = {
  image: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};

export default Hero;
